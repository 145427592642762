var Plotz = function () {
    var seven = [
        "[wo]man vs. nature", "[wo]man vs. man", "[wo]man vs. the environment",
        "[wo]man vs. machines/technology", "[wo]man vs. the supernatural",
        "[wo]man vs. self", "[wo]man vs. god/religion"
    ];
    var twenty = [
        "Quest", "Adventure", "Pursuit", "Rescue", "Escape", "Revenge", "The Riddle",
        "Rivalry", "Underdog", "Temptation", "Metamorphosis", "Transformation",
        "Maturation", "Love", "Forbidden Love", "Sacrifice", "Discovery",
        "Wretched Excess", "Ascension", "Descension"
    ];
    var thirtysix = [
        "Supplication (in which the Supplicant must beg something from Power in authority)",
        "Deliverance", "Crime Pursued by Vengeance", "Crimes of Love",
        "Vengeance taken for kindred upon kindred", "Pursuit", "Disaster",
        "Falling Prey to Cruelty of Misfortune", "Revolt", "Daring Enterprise", "Abduction",
        "The Enigma (temptation or a riddle)", "Obtaining", "Enmity of Kinsmen",
        "Rivalry of Kinsmen", "Murderous Adultery", "Madness", "Fatal Imprudence",
        "Rivalry of Superior and Inferior",
        "Involuntary Crimes of Love (example: discovery that one has married one's mother, sister, etc.)",
        "Slaying of a Kinsman Unrecognized", "Self-Sacrificing for an Ideal",
        "Self-Sacrifice for Kindred", "All Sacrificed for Passion",
        "Necessity of Sacrificing Loved Ones", "Adultery", "An Enemy Loved",
        "Discovery of the Dishonor of a Loved One", "Obstacles to Love", "Ambition",
        "Conflict with a God", "Mistaken Jealousy", "Erroneous Judgement",
        "Remorse", "Recovery of a Lost One", "Loss of Loved Ones"
    ];

    var random = function (num) {
        return Math.floor(Math.random() * num);
    };

    return {

        /**
         * Return one of the 7 plots, randomly
         *
         * @returns {string}
         */
        randomSeven: function () {
            return seven[random(7)];
        },

        /**
         * Return one of the 20 plots, randomly
         *
         * @returns {string}
         */
        randomTwenty: function () {
            return twenty[random(20)];
        },

        /**
         * Return on of the 36 plots, randomly
         *
         * @returns {string}
         */
        randomThirtysix: function () {
            return thirtysix[random(36)];
        },

        /**
         * Returns all the plots in a random order
         *
         * @returns {array}
         */
        randomAll: function () {
            // Assemble all the plots
            var array = [], i;
            for (i = 0; i < 7; i++)
                array.push(seven[i]);
            for (i = 0; i < 20; i++)
                array.push(twenty[i]);
            for (i = 0; i < 36; i++)
                array.push(thirtysix[i]);

            // Shuffle the plots
            array.sort(function() {
                return (Math.round(Math.random())-0.5);
            });

            return array;
        }
    };
}();

