var Toolz = function () {

    var initFunctions = [];

    // /**
    //  * Return the options to user for alertSuccess or alertError
    //  *
    //  * @param options
    //  * @param message
    //  * @param type
    //  * @param icon
    //  */
    // var alertOptions = function(options, message, type, icon) {
    //     var closeInSeconds = 12;
    //     options = options ? options : {};
    //     if (options === true) {
    //         options = {};
    //         closeInSeconds = 0;
    //     }
    //     options = $.extend({
    //         type: type,
    //         icon: icon,
    //         closeInSeconds: closeInSeconds,
    //         message: message,
    //         container: "#feedback-area",
    //         reset: false
    //     }, options);
    //     return options;
    // };

    return {

        // /**
        //  * Display error alert
        //  *
        //  * @param message
        //  * @param obj|true options (true to keep open)
        //  */
        // alertError: function(message, options) {
        //     Metronic.alert(alertOptions(options, message, "danger", "warning"));
        // },
        //
        // /**
        //  * Display info alert
        //  *
        //  * @param message
        //  * @param obj|true options (true to keep open)
        //  */
        // alertInfo: function(message, options) {
        //     Metronic.alert(alertOptions(options, message, "info", "info-circle"));
        // },
        //
        // /**
        //  * Display success alert
        //  *
        //  * @param message
        //  * @param obj|true options (true to keep open)
        //  */
        // alertSuccess: function(message, options) {
        //     Metronic.alert(alertOptions(options, message, "success", "check-circle"));
        // },

        // /**
        //  * Format date string to readable value
        //  *
        //  * @param date
        //  */
        // formatDateString: function (date, excludeTime) {
        //     if (excludeTime) {
        //         return moment(date).format("D-MMM-YY");
        //     }
        //     return moment(date).format("D-MMM-YY h:mma");
        // },

        /**
         * Initialize
         */
        init: function () {
            Metronic.init();
            Layout.init();

            $(".toggle").click(function (event) {
                event.preventDefault();
                $($(this).data("target")).slideToggle("slow");
            });

            // $(".alert-autohide").each(function () {
            //     var item = $(this);
            //     setTimeout(function() {
            //         item.alert("close");
            //     }, 10000);
            // });

            for (var i = 0; i < initFunctions.length; i++) {
                initFunctions[i]();
            }
        },

        /**
         * Add a callback to call on initialization
         */
        onInit: function(callback) {
            initFunctions.push(callback);
        },

        /**
         * Replace all occurrences of one string with another
         *
         * @param search
         * @param replace
         * @param str
         * @returns {string}
         */
        replaceAll: function(search, replace, str) {
            return (str + "").split(search).join(replace);
        }
    };

}();

$(function() {
    Toolz.init();
});